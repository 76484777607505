<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'新建':'编辑'}生产厂家`" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">

            <div>
                <a-form  id="components-form-demo-validate-other" :label-col="{ span: 5, offset: 0 }"
                         :wrapper-col="{ span:10 }" v-bind="formItemLayout" @submit="handleSubmit">


                    <a-form-item label="厂商简称">
                        <a-input  v-model="info.short_name" @blur="inputBlur"/>
                    </a-form-item>

                    <a-form-model-item label="厂商全称">
                        <a-input v-model="info.full_name"/>
                    </a-form-model-item>

                    <a-form-item label="拼音代码">
                        <a-input v-model="info.pinyin"/>
                    </a-form-item>

                    <a-form-item label="联系人员">
                        <a-input v-model="info.contact_name"/>
                    </a-form-item>

                    <a-form-item label="联系电话">
                        <a-input v-model="info.phone"/>
                    </a-form-item>

                    <a-form-item label="电子邮件">
                        <a-input v-model="info.email"/>
                    </a-form-item>

                    <a-form-item label="传真号码">
                        <a-input v-model="info.fax_number"/>
                    </a-form-item>

                    <a-form-item label="通讯电子">
                        <a-input v-model="info.electronics"/>
                    </a-form-item>

                    <a-form-item label="官方主页">
                        <a-input v-model="info.homepage"/>
                    </a-form-item>

                    <a-form-item label="使用状态">
                        <a-radio-group v-model="info.status" @change="onChange">
                            <a-radio :value="1">使用中</a-radio>
                            <a-radio :value="0">已停用</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span: 10, offset: 6 }">
                        <a-button  type="primary" @click="doClick()">保存</a-button>
                    </a-form-item>

                </a-form>
            </div>

        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close','edit_info', 'edit_time'],

        data: () => ({
            Py_list:[],
            info: {
                id: 0,
                short_name: '',
                full_name: '',
                pinyin:'',
                contact_name: '',
                phone:'',
                fax_number:'',
                electronics: '',
                postal_code:'',
                email:'',
                homepage:'',
                status:'',
                sort:0,
            },
            do_type: false,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },

            value: 1,

        }),
        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))
                console.log(this.info)
            },
        },
        mounted() {
            this.info = JSON.parse(JSON.stringify(this.edit_info))
        },

        methods: {

            inputBlur() {
                let name = this.info.short_name;
                this.$sa0.post({
                    url: this.$api('Py'),
                    data: {
                        name: name,
                    },

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.info.pinyin = response.data.pinyin_name
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //获取拼音首字母
            Py() {
                this.$sa0.post({
                    url: this.$api('Py'),
                    data: {
                        name: this.info.short_name,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Py_list = response.data.list;
                            //console.log(this.Py_list)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            doClick() {
                let data = {
                    id: this.info. id,
                    short_name: this.info.short_name,
                    full_name: this.info.full_name,
                    pinyin:this.info.pinyin,
                    contact_name: this.info.contact_name,
                    phone:this.info.phone,
                    fax_number:this.info.fax_number,
                    electronics: this.info.electronics,
                    postal_code:this.info.postal_code,
                    email:this.info.email,
                    homepage:this.info.homepage,
                    status:this.info.status? 1 : 0,
                    sort:0,
                }
                console.log(JSON.stringify(data))
                console.log(data.status)
                this.$sa0.post({
                    url: this.info.id !== 0 ? this.$api('Edit_manufacturer') : this.$api('Create_manufacturer'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //
            onChange(e) {
                console.log('radio checked', e.target.value);
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
</style>
