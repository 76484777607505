<template>
    <div>

            <a-form-model layout="inline" :modal="form">
                <a-form-model-item label="" >
                    <a-input v-model="form.keywords" placeholder="请输入厂商简称或拼音代码" style="width: 260px"/>
                </a-form-model-item>
                <a-form-model-item label="" >
                    <a-select v-model="form.status" style="width: 120px">
                        <a-select-option value=''>全部</a-select-option>
                        <a-select-option value=1>使用中</a-select-option>
                        <a-select-option value=0>已停用</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="" >
                    <a-button type="danger" @click="Clear()">清空</a-button>
                </a-form-model-item>

                <a-form-model-item label="" >
                    <a-button type="primary" @click="showDrawer({
                id: 0,
                short_name: '',
                full_name: '',
                pinyin:'',
                contact_name: '',
                phone:'',
                fax_number:'',
                electronics: '',
                postal_code:'',
                email:'',
                homepage:'',
                status:'',
                sort:'',
            })">添加生产厂家
                    </a-button>
                </a-form-model-item>

                <a-form-model-item label="" style="float: right" >
                    <a-button type="danger" @click="Manufacturer_export()">导出筛选数据</a-button>
                </a-form-model-item>
            </a-form-model>


        <a-row>
            <a-col :span="24">
                <table class="layui-table" lay-size="sm" >
                    <thead>
                    <tr>
                        <th>厂商简称</th>
                        <th>厂商全称</th>
                        <th>拼音代码</th>
                        <th>通讯地址</th>
                        <th style="width: 100px">联系人</th>
                        <th>联系电话</th>
                        <th>电子邮件</th>
                        <th>传真号码</th>
                        <th>官方主页</th>
                        <th style="width: 100px">是否停用</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,key) in changjia_list">
                        <td>{{item.short_name}}</td>
                        <td>{{item.full_name}}</td>
                        <td>{{item.pinyin}}</td>
                        <td>{{item.electronics}}</td>
                        <td>{{item.contact_name}}</td>
                        <td>{{item.phone}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.fax_number}}</td>
                        <td>{{item.homepage}}</td>
                        <td v-if="item.status === 0" style="color: #ff7875">{{wordType(item.status)}}</td>
                        <td v-else>{{wordType(item.status)}}</td>
                        <td>
                            <a-space>
                                <a-button type="link" @click="showDrawer(item)">编辑</a-button>
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">确认删除该项？</template>
                                    <a-button type="link" class=" redcolor">删除</a-button>
                                </a-popconfirm>
                            </a-space>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <a-pagination :current="0" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
            </a-col>
        </a-row>
        <!--添加厂商、编辑厂商-->
        <div v-if="edit_info">
            <labopen :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible"></labopen>
        </div>
    </div>
</template>

<script>
    import labopen from "../../../components/drawer/shijichangshang-add/shijichangshang-add.vue";

    export default {
        components: {
            labopen,
        },
        data() {
            return {
                form: {
                    keywords: '',
                    status: '',
                },
                page: 1,
                count: 0,
                pagesize: 0,
                edit_info: false,
                edit_time: 0,
                changjia_list: [],
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                page_show: false,//编辑入库
            };
        },
        watch: {
            form: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.mountedDO()
                },
                deep: true
            }
        },
        mounted() {
            this.mountedDO()
        },
        methods: {
            mountedDO() {
                this.page = this.$route.query.page || 1;
                this.form.keywords = this.$route.query.keywords || '';
                this.form.status = this.$route.query.status || '';
                this.Get_manufacturer()
                setTimeout(() => {
                    this.page_show = true
                }, 1)
            },
            searchClick() {
                this.$router.push({
                    query: {
                        id: this.id,
                        keywords: this.form.keywords,
                        status: this.form.status,
                        page: 1,
                    },
                })
            },
            Clear() {
                this.form.keywords = '';
                this.form.status = '';
                this.page = 1;
            },
            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        keywords: this.form.keywords,
                        name: this.form.name,
                    }
                })
            },
            // 类型转换-是否停用
            wordType(status) {
                let type_arr = ['已停用', '使用中']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 0]
                } else {
                    return status
                }
            },
            //删除
            delClick(item) {
                this.$sa0.post({
                    url: this.$api('Del_manufacturer'),
                    data: {
                        id: item.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_manufacturer()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //生成厂家导出
            Manufacturer_export() {
                console.log(JSON.stringify({
                    page: this.page,
                    status: this.form.status,
                    keywords: this.form.keywords,
                }))
                this.$sa0.down({
                    url: this.$api('Manufacturer_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        page: this.page,
                        status: this.form.status,
                        keywords: this.form.keywords,
                    },
                },)
            },

            //获取生产厂家列表
            Get_manufacturer() {
                console.log(this.form.status)
                this.$sa0.post({

                    url: this.$api('Get_manufacturer'),
                    data: {
                        id: this.id,
                        page: this.page,
                        status: this.form.status,
                        keywords: this.form.keywords,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.changjia_list = response.data.result.list;
                            console.log(this.changjia_list)
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 打开抽屉时的 函数 //新建、编辑厂商
            showDrawer(item) {
                this.edit_time = new Date() / 1;
                this.edit_info = item
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.Get_manufacturer()
                this.drawer_visible = false;
            },

        },
    };
</script>


<style scoped>
    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important; font-weight: bold;
        }
</style>
